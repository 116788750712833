'use client'

import { type PropsWithChildren } from 'react'

import { useClientOnce } from '@/src/shared/hooks/useClientOnce'
import { useDidMount } from '@/src/shared/hooks/useDidMount'
import { useTelegramMock } from '@/src/shared/hooks/useTelegramMock'

import { telegramSDKInit } from '../init'
import TelegramMiniAppLoader from '../ui/TelegramMiniAppLoader'
// import {
//   miniApp,
//   useSignal,
// } from '@telegram-apps/sdk-react';
// import { useTelegramMock } from '@/hooks/useTelegramMock';

function TelegramSDKInitProviderInner({ children }: PropsWithChildren) {
  // Mock Telegram environment in development mode if needed.
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTelegramMock()
  }

  useClientOnce(() => {
    telegramSDKInit(false)
  })

  // const isDark = useSignal(miniApp.isDark);

  return children
}

export function TelegramSDKInitProvider(props: PropsWithChildren) {
  // Unfortunately, Telegram Mini Apps does not allow us to use all features of the Server Side
  // Rendering. That's why we are showing loader on the server side.
  const didMount = useDidMount()

  return didMount ? <TelegramSDKInitProviderInner {...props} /> : <TelegramMiniAppLoader />
}
