import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["TelegramSDKInitProvider"] */ "/app/src/src/app/telegram/TelegramSDKProvider/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/images/logo-white.svg");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/images/no-avatar.png");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Avatar/Avatar.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Icon/Icon.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Container/Container.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Logo/Logo.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/LocalizedLink/LocalizedLink.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Title/Title.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/app/telegram/ui/TelegramHeader/TelegramHeader.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Footer/images/herb-warszawy.svg");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Footer/ui/Footer.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(tgApp)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ShowFeedbackButton"] */ "/app/src/src/widgets/Footer/ui/ShowFeedbackButton.tsx");
