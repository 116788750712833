import styles from './TelegramMiniAppLoader.module.scss'

const TelegramMiniAppLoader = () => {
  return (
    <div className={styles.wrapper}>
      <video src="/videos/app-preloader.mp4" width={840} height={472} autoPlay loop muted />
    </div>
  )
}

export default TelegramMiniAppLoader
